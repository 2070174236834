import React, { useState, useEffect, createContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import axios from "axios";
import Cookies from "js-cookie";
import { initializeTopologyApi } from "./api/topology";
import { initializeEquipmentApi } from "./api/equipment";
import { initializeWorkOrderApi } from "./api/workOrder";
import { initializeFilestoreApi } from "./api/filestore";
import { initializeOeeApi } from "./api/oee";
import "./App.css";
import "antd/dist/antd.css";
import messages from "./i18n/messages";
import Setting from "./component/setting/Setting";
import View from "./component/view/View";
import { message } from "antd";

export const PermissionContext = createContext();

function App() {
  const availableLang = ["zh-TW", "en-US"];
  const browserLang = window.navigator.language === "zh-TW" ? "zh-TW" : "en-US";

  const [isLogin, setIsLogin] = useState(false);
  const [permissions, setPermissions] = useState({ read: false, write: false });
  const [language, setLanguage] = useState(
    availableLang.find((lang) => lang === Cookies.get("dzLang"))
      ? Cookies.get("dzLang")
      : browserLang
  );

  window.cookieStore.addEventListener("change", (event) => {
    if (event?.changed?.[0]?.["name"] === "dzLang") {
      setLanguage(
        availableLang.find((lang) => lang === event.changed[0]["value"])
          ? event.changed[0]["value"]
          : browserLang
      );
    }
  });

  useEffect(() => {
    if (process.env.REACT_APP_MODE === "dev") {
      // local測試會進這裡
      const dev_token = ""; // local測試貼上token
      Cookies.set("token", dev_token);
      initializeTopologyApi();
      initializeEquipmentApi();
      initializeWorkOrderApi();
      initializeFilestoreApi();
      initializeOeeApi();
      setPermissions({ read: true, update: true });
      setIsLogin(true);
    } else {
      let token = Cookies.get("token");
      let user = Cookies.get("user");
      if (token !== undefined && user !== undefined) {
        let userObj = JSON.parse(user);
        axios({
          method: "get",
          url: `${process.env.REACT_APP_USER_API}/permission/app?name=portal`,
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            initializeTopologyApi();
            initializeEquipmentApi();
            initializeWorkOrderApi();
            initializeFilestoreApi();
            initializeOeeApi();
            setPermissions(res.data.permissions.topology);
            setIsLogin(true);
          })
          .catch((err) => {
            setIsLogin(false);
            message.error(messages[language]["setting.canvas.networkAbnormal"]);
            let loginUrl =
              process.env.REACT_APP_LOGIN_URL +
              "login?tenantId=" +
              userObj.tenantId;
            window.location.replace(loginUrl);
          });
      } else {
        setIsLogin(false);
      }
    }
  }, [language]);
  console.log("[VERSION]", process.env.REACT_APP_VERSION);

  return isLogin ? (
    <PermissionContext.Provider value={permissions}>
      <IntlProvider
        locale={language}
        messages={messages[language]}
        onError={() => {}}
      >
        <Switch>
          <Route exact path="/setting" component={Setting} />
          <Route exact path="/setting/:plantFloorUUID" component={Setting} />
          <Route exact path="/view/:plantFloorUUID" component={View} />
          <Redirect from="/*" to="/setting" />
        </Switch>
      </IntlProvider>
    </PermissionContext.Provider>
  ) : null;
}

export default App;
